<div
    class="border border-palette-gray-200 shadow-sm bg-white flex p-[7px] items-center rounded-lg text-sm font-semibold">
    <div
        (click)="onChange('DEPART')"
        class="min-w-[52px] rounded-lg h-9 flex justify-center items-center px-3 py-2 mr-2 cursor-pointer"
        [ngClass]="
            currentModeView === 'DEPART' 
            ? 'bg-palette-blue-50 text-branding-primary-600'
            : 'text-palette-gray-500 hover:bg-palette-gray-200'">
        {{ 'FLIGHT.EXTRA_BAGGAGE.DEPARTURE' | translate }}
    </div>
    <div
        *ngIf="itineraries?.length > 1"
        (click)="onChange('RETURN')"
        [ngClass]="
            currentModeView === 'RETURN'
            ? 'bg-palette-blue-50 text-branding-primary-600'
            : 'text-palette-gray-500 hover:bg-palette-gray-200'"
        class="min-w-[52px] h-9 rounded-lg flex justify-center items-center cursor-pointer px-3 py-2">
        {{ "FLIGHT.EXTRA_BAGGAGE.RETURN" | translate }}
    </div>
</div>
