import { Component, Input, SimpleChanges } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MulTranslationService } from 'src/app/core/services/mul-translation/mul-translation.service';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import {
    MAX_TRUNCATED_TEXT_HEIGHT_COMMENT,
    MAX_TRUNCATED_TEXT_HEIGHT,
} from '@shared/constant';
import { TranslateParamModel } from 'src/app/lib/api/translate/api.translate.model';
import { ToastType } from '@shared/enum/toast-type';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fare-rule',
  templateUrl: './fare-rule.component.html',
  styleUrls: ['./fare-rule.component.scss']
})
export class FareRuleComponent {
 
}
