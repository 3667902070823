import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SOURCE } from '@shared/enum/source.enum';
import { SOCTRIP_HOTLINE } from '@shared/constant';

@Component({
  selector: 'app-baggage-details',
  templateUrl: './baggage-details.component.html',
  styleUrls: ['./baggage-details.component.scss']
})
export class BaggageDetailsComponent {
  @Input() flightInfo: any;
  SOURCE = SOURCE;
  SOCTRIP_HOTLINE = SOCTRIP_HOTLINE;
  
  isShowBaggagePopup = false;
  tooltipStyle: { [key: string]: string } = {};
  isDirtyHover: boolean = false;
  showBaggageTooltipInfo(event?: any) {
    this.calculateTooltipPosition(event);
    this.isShowBaggagePopup = true;
    this.isDirtyHover = true;
  }
  keepBaggageTooltipInfo() {
    this.isShowBaggagePopup = true;
  }

  hideBaggageTooltipInfo() {
    this.isShowBaggagePopup = false;
  }

  calculateTooltipPosition(event: any) {
    const elementRect = event.target?.getBoundingClientRect();
    const elementTop = elementRect.top;
    const elementHeight = elementRect.height;
    const windowHeight = window.innerHeight;
    const spaceAtBottom = windowHeight - (elementTop + elementHeight);
    const spaceAtTop = elementTop;

    if (spaceAtBottom < spaceAtTop) {
      this.tooltipStyle = {
        bottom: '24px',
      };
    } else {
      this.tooltipStyle = {
        top: '24px',
      };
    }
  }
}
