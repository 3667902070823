import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CtaSigninService {
  private isShowCTASubject = new BehaviorSubject<boolean | null>(null);
  isShowCTA$: Observable<boolean | null> = this.isShowCTASubject.asObservable();
  private isFirstLoadBookingResult = false;

  constructor() { }

  hideCTA(): void {
    this.isShowCTASubject.next(false);
  }

  showCTA(): void {
    this.isShowCTASubject.next(true);
  }

  setIsFirstLoadFromSyncData(value: boolean): void {
    this.isFirstLoadBookingResult = value;
  }

  getIsFirstLoadFromSyncData(): boolean {
    return this.isFirstLoadBookingResult;
  }
}
