<div
  id="menu-bar"
  [ngClass]="handleMenuBar()"
  class="fixed top-0 z-20 h-[68px] w-full whitespace-nowrap"
>
  <lib-header
    [environment]="environment"
    [userInfo]="userInfo"
    [currentPage]="'booking'"
    [currentTheme]="currentTheme"
    [(megaVisible)]="megaVisible"
    [myBookingUrl]="myBookingUrl"
    [visibleSearch]="false"
    [visibleShopingCart]="visibleShoppingCart"
    [loginReturnUrl]="loginReturnUrl"
    [currentLanguage] = "currentLang"
    (updateLanguage)="updateLangAndCurrency($event)"
    [stickyBannerCode]="'FLIGHT_STICKY_BANNERS'"
    [popUpBannerCode]="'FLIGHT_POPUP_BANNERS'"
    [countryCode]="bannerCountry"
    [timezone]="timeZone"
    [isShowBanner]="isShowBanner"
    [bannerIdleTime]="8 * 60 * 60 * 1000"
  >
  </lib-header>
</div>
