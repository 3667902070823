import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'select-button-round-trip',
  templateUrl: './select-button-round-trip.component.html',
  styleUrls: ['./select-button-round-trip.component.scss']
})
export class SelectButtonRoundTripComponent {
    @Input() itineraries: any;
    @Input() currentModeView: any = 'DEPART';
    @Output() changeViewMode  = new EventEmitter<string>();

    onChange(view: string) {
      this.changeViewMode.emit(view);
    }
}
