import moment from "moment";

export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
};
export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];

export const DEFAULT_SEARCH_PARAMS = {
  page_num: 1,
  page_size: 25,
  num_of_adults: 1,
  num_of_children: 0,
  num_of_rooms: 1,
  child_ages_list: [],
  currency: 'USD',
  country_code: 'US',
  checkin: moment().format('yyyy-MM-DD'),
  checkout: moment().add(1, 'day').format('yyyy-MM-DD')
};

export const URL_COUNTRY_CODE = 'https://ipgeolocation.abstractapi.com/v1/?api_key=';
export const APIKEY_COUNTRY_CODE = 'da81649210074b6f8003c7903841046f';

export const TIME_OUT_REQUEST = 300000;
export const CHARACTER_TRANSLATION_LIMIT = 5000;
export const MAX_TRUNCATED_TEXT_HEIGHT = 300;

export const MAX_QTY_REACTIONS_STORAGE = 1000;
export const PAGE_SIZE_DEFAULT = 10;
export const PAGE_NUM_DEFAULT = 0;
export const MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = 135;

export const CONTACT_MAIL = "contact.flight.vn@soctrip.com";
export const SOCTRIP_HOTLINE = '1900571248';

export const MEDIA_FILE_SIZE_LIMITS_BYTES = {
  image: 5242880,
  video: 104857600
};

export const MEDIA_FILE_FORMAT_LIMITS = {
  image: [
    'image/avif',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/jpg',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'image/tiff'
  ],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/3gpp',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/quicktime'
  ]
}

export const MEDIA_TYPE = {
  image: 'Image',
  video: 'Video',
  file: 'File'
};

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const FLIGHT = {
  page_size: 9
}

export const currencies = {
  "US": { currency: "USD" },
  "VN": { currency: "VND" },
}

export const DEFAULT_SUGGEST_SEARCH_VN_EN = {
  popular_places: [
    {
      "city_name": "Ho Chi Minh",
      "country": "Vietnam",
      "airport_name": "Tan Son Nhat International Airport",
      "iata_code": "SGN",
      "object_type": "Airport"
    },
    {
      "city_name": "Ha Noi",
      "country": "Vietnam",
      "airport_name": "Noi Bai International Airport",
      "iata_code": "HAN",
      "object_type": "Airport"
    },
    {
      "city_name": "Da Nang",
      "country": "Vietnam",
      "airport_name": "Da Nang Airport",
      "iata_code": "DAD",
      "object_type": "Airport"
    },
    {
      "city_name": "Nha Trang",
      "country": "Vietnam",
      "airport_name": "Cam Ranh Airport",
      "iata_code": "CXR",
      "object_type": "Airport"
    },
    {
      "city_name": "Phu Quoc",
      "country": "Vietnam",
      "airport_name": "Phu Quoc Airport",
      "iata_code": "PQC",
      "object_type": "Airport"
    },
    {
      "city_name": "Hai Phong",
      "country": "Vietnam",
      "airport_name": "Cat Bi Airport",
      "iata_code": "HPH",
      "object_type": "Airport"
    },
    {
      "city_name": "Vinh",
      "country": "Vietnam",
      "airport_name": "Vinh city Airport",
      "iata_code": "VII",
      "object_type": "Airport"
    },
    {
      "city_name": "Hue",
      "country": "Vietnam",
      "airport_name": "Phu Bai Airport",
      "iata_code": "HUI",
      "object_type": "Airport"
    },
    {
      "city_name": "Da Lat",
      "country": "Vietnam",
      "airport_name": "Lien khuong Airport",
      "iata_code": "DLI",
      "object_type": "Airport"
    }
  ],
  recommended_places: [
    {
      "city_name": "Chu Lai",
      "country": "Vietnam",
      "airport_name": "Chu Lai Airport",
      "iata_code": "VCL",
      "object_type": "Airport"
    },
    {
      "city_name": "Thanh Hoa",
      "country": "Vietnam",
      "airport_name": "Tho Xuan Airport",
      "iata_code": "THD",
      "object_type": "Airport"
    },
    {
      "city_name": "Tuy Hoa",
      "country": "Vietnam",
      "airport_name": "Tuy Hoa Airport",
      "iata_code": "TBB",
      "object_type": "Airport"
    },
    {
      "city_name": "Pleiku",
      "country": "Vietnam",
      "airport_name": "Pleiku Airport",
      "iata_code": "PXU",
      "object_type": "Airport"
    },
    {
      "city_name": "Buon Ma Thuot",
      "country": "Vietnam",
      "airport_name": "Buon Ma Thuot Airport",
      "iata_code": "BMV",
      "object_type": "Airport"
    },
    {
      "city_name": "Dong Hoi",
      "country": "Vietnam",
      "airport_name": "Dong Hoi Airport",
      "iata_code": "VDH",
      "object_type": "Airport"
    },
    {
      "city_name": "Rach Gia",
      "country": "Vietnam",
      "airport_name": "Rach Gia Airport",
      "iata_code": "VKG",
      "object_type": "Airport"
    },
    {
      "city_name": "Ca Mau",
      "country": "Vietnam",
      "airport_name": "Ca Mau Airport",
      "iata_code": "CAH",
      "object_type": "Airport"
    },
    {
      "city_name": "Dien Bien Phu",
      "country": "Vietnam",
      "airport_name": "Dien Bien Airport",
      "iata_code": "DIN",
      "object_type": "Airport"
    },
    {
      "city_name": "Con Dao",
      "country": "Vietnam",
      "airport_name": "Con Dao Airport",
      "iata_code": "VCS",
      "object_type": "Airport"
    },
    {
      "city_name": "Quang Ninh",
      "country": "Vietnam",
      "airport_name": "Van Don International Airport",
      "iata_code": "VDO",
      "object_type": "Airport"
    },
    {
      "city_name": "Qui Nhon",
      "country": "Vietnam",
      "airport_name": "Phu Cat Airport",
      "iata_code": "UIH",
      "object_type": "Airport"
    }
  ]
};

export const DEFAULT_SUGGEST_SEARCH_VN_VI = {
  popular_places: [
    {
      "city_name": "Hồ Chí Minh",
      "country": "Vietnam",
      "airport_name": "Sân bay Quốc tế Tân Sơn Nhất",
      "iata_code": "SGN",
      "object_type": "Airport"
    },
    {
      "city_name": "Hà Nội",
      "country": "Vietnam",
      "airport_name": "Sân bay Quốc tế Nội Bài",
      "iata_code": "HAN",
      "object_type": "Airport"
    },
    {
      "city_name": "Đà Nẵng",
      "country": "Vietnam",
      "airport_name": "Sân bay Quốc tế Đà Nẵng",
      "iata_code": "DAD",
      "object_type": "Airport"
    },
    {
      "city_name": "Nha Trang",
      "country": "Vietnam",
      "airport_name": "Sân bay Cam Ranh",
      "iata_code": "CXR",
      "object_type": "Airport"
    },
    {
      "city_name": "Phú Quốc",
      "country": "Vietnam",
      "airport_name": "Sân bay Phú Quốc",
      "iata_code": "PQC",
      "object_type": "Airport"
    },
    {
      "city_name": "Hải Phòng",
      "country": "Vietnam",
      "airport_name": "Sân bay Quốc tế Cát Bi",
      "iata_code": "HPH",
      "object_type": "Airport"
    },
    {
      "city_name": "Vinh",
      "country": "Vietnam",
      "airport_name": "Sân bay Vinh",
      "iata_code": "VII",
      "object_type": "Airport"
    },
    {
      "city_name": "Huế",
      "country": "Vietnam",
      "airport_name": "Sân bay Phú Bài",
      "iata_code": "HUI",
      "object_type": "Airport"
    },
    {
      "city_name": "Đà Lạt",
      "country": "Vietnam",
      "airport_name": "Sân bay Liên Khương",
      "iata_code": "DLI",
      "object_type": "Airport"
    }
  ],
  recommended_places: [
    {
      "city_name": "Chu Lai",
      "country": "Vietnam",
      "airport_name": "Sân bay Chu Lai",
      "iata_code": "VCL",
      "object_type": "Airport"
    },
    {
      "city_name": "Thanh Hóa",
      "country": "Vietnam",
      "airport_name": "Sân bay Thọ Xuân",
      "iata_code": "THD",
      "object_type": "Airport"
    },
    {
      "city_name": "Tuy Hòa",
      "country": "Vietnam",
      "airport_name": "Sân bay Tuy Hòa",
      "iata_code": "TBB",
      "object_type": "Airport"
    },
    {
      "city_name": "Pleiku",
      "country": "Vietnam",
      "airport_name": "Sân bay Pleiku",
      "iata_code": "PXU",
      "object_type": "Airport"
    },
    {
      "city_name": "Buôn Ma Thuột",
      "country": "Vietnam",
      "airport_name": "Sân bay Buôn Ma Thuột",
      "iata_code": "BMV",
      "object_type": "Airport"
    },
    {
      "city_name": "Đồng Hới",
      "country": "Vietnam",
      "airport_name": "Sân bay Đồng Hới",
      "iata_code": "VDH",
      "object_type": "Airport"
    },
    {
      "city_name": "Rạch Giá",
      "country": "Vietnam",
      "airport_name": "Sân bay Rạch Giá",
      "iata_code": "VKG",
      "object_type": "Airport"
    },
    {
      "city_name": "Cà Mau",
      "country": "Vietnam",
      "airport_name": "Sân bay Cà Mau",
      "iata_code": "CAH",
      "object_type": "Airport"
    },
    {
      "city_name": "Điện Biên Phủ",
      "country": "Vietnam",
      "airport_name": "Sân bay Điện Biên",
      "iata_code": "DIN",
      "object_type": "Airport"
    },
    {
      "city_name": "Côn Đảo",
      "country": "Vietnam",
      "airport_name": "Sân bay Côn Đảo",
      "iata_code": "VCS",
      "object_type": "Airport"
    },
    {
      "city_name": "Quảng Ninh",
      "country": "Vietnam",
      "airport_name": "Sân bay Quốc tế Vân Đồn",
      "iata_code": "VDO",
      "object_type": "Airport"
    },
    {
      "city_name": "Qui Nhơn",
      "country": "Vietnam",
      "airport_name": "Sân bay Phù Cát",
      "iata_code": "UIH",
      "object_type": "Airport"
    }
  ]
}

